import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "container"];
  static classes = ["active"];

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.containerTarget.classList.contains(this.activeClass)) {
      document.body.classList.remove("modal-open");
      this.containerTarget.classList.remove(this.activeClass);

      this.containerTarget.classList.add("menu--close");
      setTimeout(() => {
        this.containerTarget.classList.remove("menu--close");
      }, 300);
    } else {
      document.body.classList.add("modal-open");
      this.containerTarget.classList.add(this.activeClass);
    }
  }
}
