import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="popup"
export default class extends Controller {
  initialize() {
    if (localStorage.getItem("popAnniversaryOne") == "1") {
      return;
    }
    this.onKeydown = this.onKeydown.bind(this);
  }

  connect() {
    if (localStorage.getItem("popAnniversaryOne") == "1") {
      this.element.remove();
      return;
    }

    window.addEventListener("keydown", this.onKeydown);

    setTimeout(() => {
      this.element.classList.toggle("flex");
      this.element.classList.toggle("hidden");
    }, 5000);

    setTimeout(() => {
      this.element.classList.toggle("opacity-0");
    }, 5010);
  }

  disconnect() {
    window.removeEventListener("keydown", this.onKeydown);
  }

  close(e) {
    this.element.remove();

    document.body.classList.remove("modal-open");
    window.removeEventListener("keydown", this.onKeydown);

    localStorage.setItem("popAnniversaryOne", "1");
  }

  onKeydown(e) {
    if (e.key === "Escape") {
      this.close(e);
    }
  }

  navigate(e) {
    e.preventDefault();
    localStorage.setItem("popAnniversaryOne", "1");
    Turbo.visit("/registrar");
  }
}
