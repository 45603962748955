import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];

  submit(e) {
    e.preventDefault();
    this.submitTarget.click();
  }
}
