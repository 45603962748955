import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['kind'];
  static classes = ['company'];

  setCustomerKind(e) {
    e.preventDefault();
    if (e.target.dataset.kind === '0') {
      this.element.classList.remove(this.companyClass);
      this.kindTarget.value = 'person';
    } else if (e.target.dataset.kind === '1') {
      this.element.classList.add(this.companyClass);
      this.kindTarget.value = 'company';
    }
  }
}
