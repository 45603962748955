import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="company-form"
export default class extends Controller {
  static targets = ["companyName"];

  async setCompanyName(e) {
    if (!e.target.value || e.target.value.length < 9) return;
    this.companyNameTarget.parentElement.classList.remove("hidden");

    const lastValue = this.companyNameTarget.value;
    this.companyNameTarget.disabled = true;
    this.companyNameTarget.value = "Buscando...";

    try {
      const response = await get(`/taxpayers?rnc=${e.target.value}`);
      if (!response.ok) return;
      const body = await response.json;

      this.companyNameTarget.disabled = false;
      if (!body) {
        this.companyNameTarget.value = lastValue;
        return;
      }
      this.companyNameTarget.value = body.name;
    } catch (e) {
      this.companyNameTarget.disabled = false;
      this.companyNameTarget.value = lastValue;
      /* handle error */
    }
  }
}
