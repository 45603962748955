import { Controller } from "@hotwired/stimulus";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];

  initialize() {
    this.initializeTribute = this.initializeTribute.bind(this);
  }

  connect() {
    document.addEventListener("trix-initialize", this.initializeTribute);
  }

  disconnect() {
    document.removeEventListener("trix-initialize", this.initializeTribute);
    this.tribute.detach(this.fieldTarget);
  }

  initializeTribute() {
    this.editor = this.fieldTarget.editor;
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "name",
      values: this.fetchUsers,
      menuItemTemplate: function(item) {
        return item.original.content;
      },
    });
    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced);
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch((error) => callback([]));
  }

  replaced(e) {
    let mention = e.detail.item.original;
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos + startPos, position + 1]);
    this.editor.deleteInDirection("backward");
  }
}
