import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "productResults",
    "estimateContainer",
    "arrowText",
    "arrowIcon",
  ];

  initialize() {
    this.displayProductResults = true;
  }

  click(e) {
    e.preventDefault();
    const copyText = this.textTarget;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    this.tooltipTarget.innerHTML = "¡Copiado!";
  }

  toggleProductResults(e) {
    e.preventDefault();
    if (this.displayProductResults) {
      this.productResultsTarget.classList.remove(
        "h-[calc(100dvh-208px)]",
        "md:h-[calc(50dvh-68px)]",
      );
      this.productResultsTarget.classList.add("h-0", "py-0");
      this.estimateContainerTarget.classList.remove("md:h-[calc(50dvh-100px)]");
      this.estimateContainerTarget.classList.add("h-[calc(100dvh-168px)]");
      this.arrowTextTarget.innerHTML = "Ocultar";
      this.arrowIconTarget.classList.add("rotate-180");
      this.displayProductResults = false;
    } else {
      this.showProductResults();
    }
  }

  showProductResults() {
    this.productResultsTarget.classList.remove("h-0", "py-0");
    this.productResultsTarget.classList.add(
      "h-[calc(100dvh-208px)]",
      "md:h-[calc(50dvh-68px)]",
    );
    this.estimateContainerTarget.classList.add("md:h-[calc(50dvh-100px)]");
    this.estimateContainerTarget.classList.remove("h-[calc(100dvh-168px)]");
    this.arrowTextTarget.innerHTML = "Ver";
    this.arrowIconTarget.classList.remove("rotate-180");
    this.displayProductResults = true;
  }

  toggleOriginalPrice(e) {
    e.preventDefault();
    this.element.classList.toggle("with-original-price");
    e.target.classList.toggle("bg-blue-50");
    e.target.classList.toggle("border-slate-200");
  }
}
