import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.onKeydown = this.onKeydown.bind(this);
  }

  connect() {
    window.addEventListener("keydown", this.onKeydown);
  }

  disconnect() {
    window.removeEventListener("keydown", this.onKeydown);
  }

  open() {
    document.body.classList.add("modal-open");
  }

  close(e) {
    if (!this.isSuccessModal()) {
      e.preventDefault();
    }

    this.element.remove();

    document.body.classList.remove("modal-open");
    window.removeEventListener("keydown", this.onKeydown);
  }

  closeTab(e) {
    e.preventDefault();
    window.close();
  }

  isSuccessModal() {
    return document.querySelector(".c-checkout-success");
  }

  onKeydown(e) {
    if (e.key === "Escape") {
      this.close(e);
    }
  }
}
