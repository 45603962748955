import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "tooltip"];

  click(e) {
    e.preventDefault();
    const copyText = this.textTarget;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    this.tooltipTarget.innerHTML = "¡Copiado!";
  }

  onmouseout() {
    this.tooltipTarget.innerHTML = "Copiar";
  }
}
