import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "input"];
  static values = { path: String };

  connect() {
    this.inputTarget.addEventListener("keyup", (e) => {
      if (e.keyCode === 13) {
        this.linkTarget.click();
      }
    });
  }

  changeUrl(e) {
    if (e.target.value) {
      this.linkTarget.href = `${this.pathValue}/${e.target.value}`;
    } else {
      this.linkTarget.href = this.pathValue;
    }
  }
}
