import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['filterToggler'];
  static classes = ['filtersActive'];

  toggleFilters() {
    if (this.element.classList.contains(this.filtersActiveClass)) {
      this.element.classList.remove(this.filtersActiveClass);
      this.element.scrollIntoView({ behavior: 'smooth' });
      this.filterTogglerTarget.innerHTML =
        'Mostrar filtros <i class="fas fa-chevron-down"></i>';
    } else {
      this.element.classList.add(this.filtersActiveClass);
      this.element.scrollIntoView({ behavior: 'smooth' });
      this.filterTogglerTarget.innerHTML =
        'Ocultar filtros <i class="fas fa-chevron-up"></i>';
    }
  }
}
