import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      spaceBetween: 20,
      slidesPerView: "auto",
    });
  }
}
