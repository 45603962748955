import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["door", "pickup", "submit"];

  deliveryMethodChange(e) {
    if (e.target.value === "door_to_door") {
      this.pickupTarget.classList.remove("checkout-delivery-pickup--active");
      this.doorTarget.classList.add("checkout-delivery-door--active");
    } else if (e.target.value === "pickup") {
      this.pickupTarget.classList.add("checkout-delivery-pickup--active");
      this.doorTarget.classList.remove("checkout-delivery-door--active");
    }
  }

  submit(e) {
    e.preventDefault();
    this.submitTarget.click();
  }
}
