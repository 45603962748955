import { Controller } from "@hotwired/stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  connect() {
    new AutoNumeric(this.element, {
      digitGroupSeparator: ",",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      currencySymbol: "RD$\u202f",
      currencySymbolPlacement:
        AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod: "D",
      decimalPlaces: 2,
      selectNumberOnly: true,
      unformatOnSubmit: true,
    });
  }
}
