import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="warehouse-location"
export default class extends Controller {
  connect() {}

  process(e) {
    const input = e.target.value;
    const cleaned = input.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    const formatted = cleaned.replace(
      /^([A-Z])(\d*)?([A-Z])?.*$/i,
      function (match, p1, p2, p3, p4) {
        let result = p1;

        if (p2) {
          result += "-" + p2;
          if (p3) {
            result += "-" + p3;
            if (p4) {
              result += p4;
            }
          }
        }

        return result;
      },
    );

    this.element.value = formatted.toUpperCase();
  }
}
