import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name"];

  onChange(e) {
    e.preventDefault();
    if (e.target.files.length === 0) return;
    this.nameTarget.innerHTML = e.target.files[0].name;
  }
}
