import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Pagination, Navigation } from "swiper/modules";

Swiper.use([Pagination, Navigation]);

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
      breakpoints: {
        // when window width is >= 480px
        576: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 6,
          slidesPerGroup: 6,
        },
      },
    });
  }
}
