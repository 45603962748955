import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = { modal: String };

  rowClick(e) {
    const path = e.target.parentNode.dataset.path || e.target.dataset.path;
    if (this.modalValue) {
      Turbo.visit(path, { frame: this.modalValue });
    } else {
      Turbo.visit(path);
    }
  }
}
