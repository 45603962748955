import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="hidden-content"
export default class extends Controller {
  static targets = ["container"];

  toggle(e) {
    e.preventDefault();
    this.containerTarget.classList.toggle("blur");
    this.containerTarget.classList.toggle("select-none");

    e.target.classList.toggle("absolute");
    e.target.classList.toggle("translate-x-[-50%]");
    e.target.classList.toggle("translate-y-[-50%]");
    e.target.classList.toggle("ml-auto");
    if (e.target.innerHTML === "Ver contenido") {
      e.target.innerHTML = "Ocultar contenido";
    } else {
      e.target.innerHTML = "Ver contenido";
    }
  }
}
