import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quantity"];
  static values = { maxStock: Number, min: { type: Number, default: 0 } };

  addToCart(e) {
    if (
      !parseInt(this.quantityTarget.value) &&
      !parseInt(this.quantityTarget.value) >= 1
    ) {
      e.preventDefault();
    }
  }

  sanitizeQuantityValue(e) {
    this.quantityTarget.value = this.quantityTarget.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");

    if (parseInt(e.target.value, 10) + 1 > this.maxStockValue)
      this.quantityTarget.value = this.maxStockValue;
  }

  increaseQuantity(e) {
    e.preventDefault();
    if (parseInt(this.quantityTarget.value, 10) + 1 > this.maxStockValue)
      return;
    this.quantityTarget.value = parseInt(this.quantityTarget.value, 10) + 1;
  }

  decreaseQuantity(e) {
    e.preventDefault();
    if (this.quantityTarget.value > this.minValue) {
      this.quantityTarget.value = parseInt(this.quantityTarget.value, 10) - 1;
    }
  }
}
