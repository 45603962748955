import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";

Swiper.use([Navigation, Autoplay]);

export default class extends Controller {
  connect() {
    new Swiper(".l-hero-slideshow", {
      spaceBetween: 10,
    });
  }
}
