import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["active"];

  toggle(e) {
    e.preventDefault();
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle(this.activeClass);
  }
}
