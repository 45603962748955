import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    window.addEventListener("beforeprint", this.beforeprint);
    window.addEventListener("afterprint", this.afterprint);
  }

  disconnect() {
    window.removeEventListener("beforeprint", this.beforeprint);
    window.removeEventListener("afterprint", this.afterprint);
  }

  print(e) {
    e.preventDefault();
    window.print();
  }

  beforeprint() {
    document.body.classList.add("printing");
  }

  afterprint() {
    document.body.classList.remove("printing");
  }
}
