import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Pagination, Navigation, Thumbs, Keyboard } from "swiper/modules";

Swiper.use([Pagination, Navigation, Thumbs, Keyboard]);

export default class extends Controller {
  static targets = ["thumbs", "display"];

  connect() {
    let thumbs;

    if (this.hasThumbsTarget) {
      thumbs = new Swiper(this.thumbsTarget, {
        loop: false,
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
      });
    }

    new Swiper(this.displayTarget, {
      loop: true,
      spaceBetween: 10,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: thumbs,
      },
    });
  }
}
